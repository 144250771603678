/*
filtra gli slider che hanno i seguenti selettori sulla base di un parametro (classe css assegnata da brx alle slide in base al loro tipo di visualizzazione)
content-for-logged-slider -> se la visibilitá del documento (card, herobanner) é per utenti loggati
content-for-guest-slider -> se la visibilitá del documento (card, herobanner) é per utenti guest
*/
function filterSliders(excludeClassName) {
    /*
    .js-heroBannerSlider -> hero-banner-centered-macro
    .js-herobanner-slider .slider, .js-herobanner-sliders -> hero-banner-component-macro, hero-banner-full-screen-macro, hero-banner-fullscreen-txt-center-macro, hero-banner-txt-center-macro
     .js-sliderCardsPartners -> cards-partners-component-macro
     .js-sliderCardsGrid -> cards-grid-component-macro
     .js-slider-cards-component, .js-slider-custom-cards -> cards-component-macro, two-column-component-macro
     .cmp_cards .listingItems -> colors-component-macro, cards-4columns-component-macro, cards-minimal-component-macro
     */

    let slickSelectors = [
        ".js-herobanner-sliders .slider",
        ".js-herobanner-slider .slider",
        ".js-heroBannerSlider",
        ".js-sliderCardsPartners",
        ".js-sliderCardsGrid",
        ".js-slider-cards-component",
        ".cmp_cards .listingItems",
        ".js-slider-custom-cards",
    ];

    // Iteriamo su ogni selettore
    slickSelectors.forEach(function(selector) {
        let $slider = $(selector);

        // Verifica se esiste almeno un elemento slider
        if ($slider.length) {

            $slider.each(function() {
                let $currentSlider = $(this);

                // Assicurati che lo slider sia stato inizializzato con Slick
                if ($currentSlider.hasClass("slick-initialized")) {
                    try {
                        // Rimuovi eventuali filtri precedenti
                        $currentSlider.slick('slickUnfilter');

                        // Applica il filtro per escludere gli elementi con la classe specificata
                        $currentSlider.slick('slickFilter', function(index, element) {
                            return !$(element).hasClass(excludeClassName);
                        });
                    } catch (error) {}
                } else {}
            });
        }
    });
}

//funzione di filtro degli slider sulla base di una variabile globale
function filterSliderBasedOnGlobalVariable(){
    if (window.userVisibility === "logged"){
        filterSliders("content-for-guest-slider");
    } else {
        filterSliders("content-for-logged-slider");
    }
}

//evento scatenato da React ad ogni cambiamento dello user data
window.addEventListener("userVisibilityChanged", () => {
    filterSliderBasedOnGlobalVariable()
});




